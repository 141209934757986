<template>
  <div class="pb-9">
    <v-container>
      <v-layout wrap>
        <v-flex
          sm12
          md6
          offset-md3
        >
          <v-card
            elevation="4"
            light
            tag="section"
          >
            <v-card-title>
              <v-layout
                wrap
                justify-center
              >
                <h3 class="headline">
                  Activity Logs
                </h3>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-label>
                <ul>
                  <li class="mb-2">
                    Enter Date Range and View Logs
                  </li>
                  <li class="mb-4">
                    Logs are accessible after 24 hours
                  </li>
                </ul>

                <br />
              </v-label>
              <v-form>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="startDate"
                      outline
                      label="Start Date"
                      type="datetime-local"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="endDate"
                      outline
                      label="End Date"
                      type="datetime-local"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="searchBtnDisabled"
                  color="primary"
                  @click="GetLogsInDateRange()"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          sm12
          md6
          offset-md3
        >
          <v-layout
            align-center
            justify-space-between
          >
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <div v-if="displayTest">
        <v-data-table
          :headers="aLogColumns"
          :items="activityLogList"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
          class="elevation-1"
          loading="loadingList"
        >
          <template
            v-slot:top
          >
            <v-toolbar
              :elevation="8"
              color="primary"
              dark
              dense
              shaped
            >
              <v-toolbar-title>
                <strong>Logs Found</strong>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:expanded-items="{ headers, item }">
            <tr>
              <td>
                {{ JSON.stringify(item) }}
              </td>
              <p
                class="d-flex justify-center ps-5 mt-3 mb-3"
              >
                Reversion History
              </p>
              <br />
              <td
                v-if="item.reversionHistory.length > 0"
                :colspan="2"
              >
                <div
                  v-for="reversion in item.reversionHistory"
                  :key="reversion.id"
                  class="d-flex justify-center ps-5 mt-3 mb-3"
                >
                  {{ reversion }}
                  <v-btn
                    class="ml-5"
                    rounded
                    color="#E5D4FF"
                    light
                    :disabled="restoreBtnDisabled"
                    @click="RevertUsingReversionId(reversion.id)"
                  >
                    Go back to this one
                  </v-btn>
                </div>
              </td>
              <td v-else>
                No Reversions in history
              </td>
              <p
                class="d-flex justify-center ps-5 mt-3 mb-3"
              >
                Version History
              </p>
              <td
                v-if="item.versionHistory.length > 0"
                :colspan="2"
              >
                <div
                  v-for="version in item.versionHistory"
                  :key="version.id"
                  class="d-flex justify-center"
                >
                  <br />
                  {{ version.jSON }}
                  <v-btn
                    class="ml-5"
                    rounded
                    color="#E5D4FF"
                    light
                    :disabled="restoreBtnDisabled"
                    @click="RevertUsingVersionId(version.versionId, item.id)"
                  >
                    Go back to this one
                  </v-btn>
                </div>
              </td>
              <td v-else>
                No version history
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useActivityLogsService from '@/store/useActivityLogsService'
import ActivityLogService from '@/services/ActivityLogService'

export default {
  setup() {
    const LOGS_APP = 'app-activity-logs'

    // Register module
    if (!store.hasModule(LOGS_APP)) {
      store.registerModule(LOGS_APP, ActivityLogService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LOGS_APP)) store.unregisterModule(LOGS_APP)
    })

    const {
      loadingList,
      aLogColumns,
      activityLogList,

      revertEntity,
      fetchActivityLogsByDate,
      test,
    } = useActivityLogsService()

    return {
      loadingList,
      aLogColumns,
      activityLogList,

      revertEntity,
      fetchActivityLogsByDate,
      test,
    }
  },
  data() {
    const startDates = new Date()

    return {
      restoreBtnDisabled: false,
      searchBtnDisabled: false,
      displayTest: false,
      startDates,
      startDate: startDates,
      endDate: startDates,
      jacketId: '',
      usersName: '',
      modelType: '',
      expanded: [],
      singleExpand: false,
      logModels: [
        'License',
        'UserLicense',
        'AllergyType',
        'PatientAllergy',
        'ICD10ProblemCode',
        'LicenseType',
        'AllergySymptom',
        'Patient',
        'ReactionCause',
        'ReactionStatus',
        'PatientReaction',
        'DistributionSchedule',
        'PatientScript',
        'PatientSite',
        'NameType',
        'Gender',
        'Race',
        'Alert',
        'PatientLab',
        'PatientOrder',
        'PatientImage',
        'PatientAppointment',
        'PatientVitals',
        'MARDistribution',
        'PatientTreatment',
        'PatientTaskType',
        'Task',
        'PatientTask',
        'PatientAlert',
        'RolePermission',
        'UserPhone',
        'UserEmail',
        'UserRole',
        'Role',
        'User',
        'Bed',
        'Cell',
        'Pod',
        'Site',
        'Honorific',
        'Permission',
        'Setting',
        'SiteSetting',
        'SiteEmail',
        'SitePhone',
        'SiteMedTime',
        'UserSite',
        'SiteSystem',
        'ClientSystem',
        'Batch',
        'SiteMedication',
        'Medication',
        'SettingGroup',
        'ConditionVerification',
        'ActionCode',
        'PatientForm',
        'FormSite',
        'FormStyle',
        'SiteSickestPatient',
        'NameSuffix',
        'Phone',
        'Email',
        'PhoneType',
        'PatientProblem',
        'ConditionSeverity',
        'MedispanAllergy',
        'ExternalUser',
        'APIPermission',
        'APIKeyToken',
        'OpenFDAItem',
        'RXNormItem',
        'UserToken',
        'AuthRoleClaim',
        'AuthLogin',
        'AuthClaim',
        'MedispanAllergyDictionary',
      ],
    }
  },
  mounted() {
    this.test()
  },
  methods: {
    RevertUsingReversionId(value) {
      this.loadingList = true
      this.restoreBtnDisabled = true

      this.displayTest = true
      const outgoingDTO = {
        usersId: '00000000-0000-0000-0000-000000000000',
        usersName: 'string',
        siteId: '00000000-0000-0000-0000-000000000000',
        jId: '',
        startDate: new Date(this.startDate),
        endDate: new Date(this.endDate),
        versionId: 0,
        activityLogId: '00000000-0000-0000-0000-000000000000',
        idOfDesiredEntityToRestore: '00000000-0000-0000-0000-000000000000',
        restoreLastVersion: false,
        modelType: 'string',
        changeCompleted: 'string',
        reversionEntityId: value,
        updateReversion: true,
      }

      this.revertEntity(outgoingDTO)
      this.displayTest = true
    },
    RevertUsingVersionId(value, aLogId) {
      this.loadingList = true
      this.restoreBtnDisabled = true

      this.displayTest = true

      // the value is the activity log id
      const outgoingDTO = {
        usersId: '00000000-0000-0000-0000-000000000000',
        usersName: 'string',
        siteId: '00000000-0000-0000-0000-000000000000',
        jId: '',
        startDate: new Date(this.startDate),
        endDate: new Date(this.endDate),
        versionId: value,
        activityLogId: aLogId,
        idOfDesiredEntityToRestore: '00000000-0000-0000-0000-000000000000',
        restoreLastVersion: true,
        modelType: 'string',
        changeCompleted: 'string',
        reversionEntityId: '00000000-0000-0000-0000-000000000000',
        updateReversion: false,
      }

      this.revertEntity(outgoingDTO)
      this.displayTest = true
    },
    GetLogsInDateRange() {
      this.loadingList = true
      this.searchBtnDisabled = true

      const datesDTO = {
        startDate: new Date(this.startDate),
        endDate: new Date(this.endDate),
      }

      console.log(`reached dates: ${datesDTO}`)

      this.fetchActivityLogsByDate(datesDTO)
      this.displayTest = true
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn,
.v-card {
  border-radius: 4px;
  color: black;
}

.v-card__title {
  text-transform: uppercase;
}
</style>
